import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';

import {
  requestBearerTokenInterceptor,
  requestParamsSerializerInterceptor,
  requestApiInterceptor,
} from './interceptors';

export let apiClient: AxiosInstance = axios;

export function createApiClient(defaults: CreateAxiosDefaults) {
  apiClient = axios.create(defaults);

  const requestBearerTokenInterceptorId = apiClient.interceptors.request.use(requestBearerTokenInterceptor);

  apiClient.interceptors.request.use(requestParamsSerializerInterceptor);

  const requestApiInterceptorId = apiClient.interceptors.request.use(requestApiInterceptor);

  return {
    requestApiInterceptorId,
    requestBearerTokenInterceptorId,
  };
}

